import { IonButton, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonPage, IonText, IonTitle, IonToolbar } from '@ionic/react';
import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { heart, medkit} from 'ionicons/icons';
import { Route } from 'react-router';
import Login from './Login';
import './Menu.css';


const Menu: React.FC = () => {
  return (
    
    <IonPage>
 


      <IonContent fullscreen className="menu_background">

      <div className='spa'>
      <IonCard>
      <IonCardHeader>
      <IonButton fill="solid" color="dark" expand="full" href='/login'>
        <IonIcon slot="start"  icon={heart}></IonIcon>
        Sou Dono
      </IonButton>
      </IonCardHeader>
      <IonCardContent>  
       Veja seus pets e como está a saúde dos bichanos.
      </IonCardContent>
    </IonCard>

    <IonCard>
      <IonCardHeader>
      <IonButton fill="solid" color="dark" expand="full" routerLink='./loginVet'>
        <IonIcon slot="start"  icon={medkit}></IonIcon>
        Sou Veterinário
        </IonButton>
      </IonCardHeader>

      <IonCardContent>
       Acesse seu Consultório Online.
      </IonCardContent>
    </IonCard>
    </div>
      </IonContent>
    </IonPage>
  );
};

export default Menu;