import { useIonLoading ,IonButton, IonIcon, IonCard,useIonAlert, IonCardContent, IonCardHeader, IonContent, IonHeader, IonLabel, IonPage, IonTitle, IonToolbar, IonItem, IonInput } from '@ionic/react';
import { bookOutline, heart, medkit, keyOutline, constructOutline} from 'ionicons/icons';
import { useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { Route, useHistory } from "react-router-dom";
import './Login.css';

import getDados, { setId, idDono, getPets } from './Provedor';


const urlServer = "https://rlamateriais.com.br/petsvita";




const Login: React.FC = () => {
  let history = useHistory();
  
  const [userName, setUserName] = useState("");
  const [senha, setSenha] = useState("");
  const [tipo, setTipo] = useState("D");
  const [statusLogin, setStatusLogin] = useState("");
  const [msg, setMsg] = useState("");
  const [presentAlert] = useIonAlert();
  const [present, dismiss] = useIonLoading();
  
  const jsonLogin = ({t: tipo, u: userName, p: senha});
  

const httpReq = axios.create({
  baseURL: urlServer
});


async function loginDono() {
  //Define D => DONO
  httpReq.post("/login.php", jsonLogin).then((response) => {
  //Recebe Mensagem do Servidor 
  setMsg(response.data.msg);
  //Login Negado
  if(response.data.status == "0") { 
    mensagem(response.data.msg);
    setStatusLogin(response.data.status); 
  } else { 
   //Login Aprovado
   setId(response.data.id); //Passa ID do Dono para Variavel
   console.log(idDono);
    if(!idDono) {
       console.log("Não foi passado o ID do Dono!")
      } else {
      present({
        message: 'Carregando informações...',
        duration: 3000
      });
    getDados(idDono);
    getPets(idDono);
    //Abre Painel  
    history.push('/PainelDono'); 
    }
  }

  }).catch((error) => {
    console.log(error);
  });
}
//Exibe Mensagem
function mensagem(msg: any) {
  presentAlert({
    header: 'Login como Dono',
    subHeader: '',
    message: msg,
    buttons: ['OK'],
  })
}

function guiaVacinacao() {
  return console.log('GUIA DE VACINAÇÃO');
}

    return (
      <IonPage>
      <IonContent fullscreen className='ion-padding'>
      <div className='espaco_login'>
      <IonCard>
      <IonCardHeader color="dark">
      <IonLabel><h1><IonIcon slot="start"  icon={heart}></IonIcon>  &nbsp;<b>Sou Dono</b></h1></IonLabel>
      </IonCardHeader>
      <IonCardContent className='card_login'> 
       <br></br>
      <IonLabel><b>Usuário:</b></IonLabel>
      <IonItem>
        <IonInput placeholder="Usuário, Email..." name="userName" id="userName" value={userName} onIonChange={(e: any) => setUserName(e.target.value)}></IonInput>
      </IonItem>
      <br></br>
      <IonLabel><b>Senha:</b></IonLabel>
      <IonItem>
        <IonInput type='password' id="senha" name="senha" value={senha} onIonChange={(e: any) => setSenha(e.target.value)}></IonInput>
      </IonItem>
      <IonItem>
      <IonLabel color="danger">Esqueci minha senha!</IonLabel> 
      </IonItem>
      <IonItem>
      <IonButton color="primary" fill="clear" routerLink="/cadDono/cadDono">Não tem cadastro? Cadastre-se!</IonButton> 
      </IonItem>
      </IonCardContent>
      <br></br>
      
      <IonButton color="dark" expand="block" onClick={loginDono}>
      <IonIcon slot="start"  icon={keyOutline}></IonIcon>
        Entrar
      </IonButton>
      
      <IonButton color="dark" fill="outline" expand="block" onClick={guiaVacinacao}>
      <IonIcon slot="start"  icon={bookOutline}></IonIcon>
        Guia de Vacinação
      </IonButton> 
      
    </IonCard>
    </div>
        </IonContent>
      </IonPage>
    );
  };
  
  
  export default Login;

function toast(response: AxiosResponse<any, any>) {
  throw new Error('Function not implemented.');
}

function presentAlert(arg0: { header: string; subHeader: string; message: string; buttons: string[]; }) {
  throw new Error('Function not implemented.');
}
  