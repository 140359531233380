import { IonButton, IonButtons, IonContent, IonHeader, IonRouterOutlet, IonTab, IonTitle, IonToolbar, useIonViewDidEnter, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';

import { IonPage,IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel, IonBadge } from '@ionic/react';
import { IonReactMemoryRouter, IonReactRouter } from '@ionic/react-router';
import axios from 'axios';

import { calendar,home, searchSharp, eyedropSharp, pawSharp,person,call, settings, personCircle, map, informationCircle, calendarSharp } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import './PainelDono.css';


import TabPerfil from './TabPerfil';
import TabPets from './TabPets';
import TabBuscar from './TabBuscar';
import { setId, idDono } from './Provedor';

import getDados, {getPets, JsonDados} from './Provedor';
import Home from './Home';
import petPerfil from './petPerfil';
import TabAgendamentos from './TabAgendamentos';

const PainelDono: React.FC<RouteComponentProps> = () => {

  return (

  <IonReactRouter>
  <IonTabs>
  <IonRouterOutlet >
  
  <Route  path="/tabPerfil"><TabPerfil /> </Route>
  <Route  path="/tabPets"><TabPets /> </Route>
  <Route  path="/tabBuscar"><TabBuscar /> </Route>
  <Route  path="/tabAgendamentos"><TabAgendamentos /> </Route>
  
  </IonRouterOutlet>
 
      <IonTabBar slot="bottom" color="dark">
 
            <IonTabButton tab="tabPerfil" href="/tabPerfil">
              <IonIcon icon={person} />
              <IonLabel>Perfil</IonLabel>
            </IonTabButton>
            <IonTabButton tab="TabPets" href="/tabPets">
              <IonIcon icon={pawSharp} />
              <IonLabel>Pets</IonLabel>
            </IonTabButton>
            <IonTabButton tab="TabBuscar" href="/tabBuscar">
              <IonIcon icon={searchSharp} />
              <IonLabel>Buscar</IonLabel>
            </IonTabButton>
            <IonTabButton tab="TabAgendamentos" href="/tabAgendamentos">
              <IonIcon icon={calendarSharp} />
              <IonLabel>Agendamentos</IonLabel>
            </IonTabButton>
          </IonTabBar>
        
         </IonTabs> 
         </IonReactRouter>
         
  ); 
};

export default PainelDono;
