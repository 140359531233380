import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonButton, IonButtons, IonContent, IonHeader, IonRouterOutlet, IonTab, IonTitle, IonToolbar, useIonViewDidEnter, useIonViewWillEnter, IonList, IonItem, IonThumbnail, IonFab, IonFabButton, IonModal, IonAvatar, IonInput, IonSelect, IonSelectOption, useIonAlert, useIonLoading } from '@ionic/react';
import { IonPage, IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel, IonBadge } from '@ionic/react';
import { personCircle } from 'ionicons/icons';
import { eye, trash, add, refresh, camera, closeCircle } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import './PainelDono.css';
import { useHistory } from 'react-router';
import ListPets from '../components/ListPets';
import { urlServer } from './Provedor';
import { racas, sexoPet } from './Provedor';
import axios from 'axios';
import { idDono, getPets } from './Provedor';



const TabPets: React.FC = () => {

  let history = useHistory();
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const modal = useRef<HTMLIonModalElement>(null);
  const [idPet, setIdPet] = useState();
  const [nome, setNome] = useState();
  const [especie, setEspecie] = useState();
  const [raca, setRaca] = useState();
  const [sexo, setSexo] = useState();
  const [peso_atual, setPeso] = useState();
  const [url_foto, setUrlFoto] = useState();
  const [nascimento, setNascimento] = useState();
  const [presentAlert] = useIonAlert();
  const [present, dismiss] = useIonLoading();

  const fileInput = useRef(null);

  const jsonCadastroPet = ({ id_dono: idDono, nome: nome, especie: especie, raca: raca, sexo: sexo, peso_atual: peso_atual, url_foto: url_foto, nascimento: nascimento });

  const httpReq = axios.create({
    baseURL: urlServer
  });

  function dismissDlg() {
    modal.current?.dismiss();
    setIsOpenAdd(false);
  }

async function cadastrarPet() {
    httpReq.post("/cadastroPet.php", jsonCadastroPet).then((response) => {
      if (response.data.status == "1") {
        present({
          message: response.data.msg,
          duration: 3000
        });
        //Atualiza pagina inicial para mostrar os pets
        dismissDlg();
        
      } if (response.data.status == "0") {
        present({
          message: response.data.msg,
          duration: 3000
        });
      }

    }).catch((error) => {
      console.log(error);
    });
}

var photo : File;
var urlFoto : any;
var f = (document as any).getElementById("file-upload");
var foto = (document as any).getElementById("perfilPet");
        
async function photoChange(e : any) {
    photo = e.target.files[0];
    console.log(photo);
    if(!photo) {
      f.click();
    } else {
      onSubmit();
    }
};

const photoSelect = () => {
  f.click(); 
}


async function onSubmit(){
    const formData = new FormData();
    formData.append('avatar', photo);
    const request = axios.post(urlServer+'/upload.php', formData)
        .then(function(response){

            urlFoto = response.data.url;
            console.log('Imagem enviada com sucesso!', photo);
            
        });
    
        return foto.src = urlFoto;   
}
function atualiza() {
  if(!urlFoto) {
    f.click();
  } else { 
    foto.src = urlFoto;
    setUrlFoto(urlFoto);
    console.log(url_foto)
  }
}

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle><b>Meus Pets</b></IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
            <ListPets />
          </IonCardContent>
        </IonCard>


        <IonModal animated={true} keepContentsMounted={true} id="pet-modal-add" isOpen={isOpenAdd}>
          <IonContent className="ion-padding">
            <div className="wrapper">

              <IonList lines="none">
                <IonItem color="dark">
                  <IonLabel><h1><b>Cadastrar Novo Pet</b></h1></IonLabel>
                </IonItem>
                <IonItem>
                  <IonAvatar className='profile_pet'>
                    <img id="perfilPet" src={urlFoto} />
                  </IonAvatar>
                  
                </IonItem>
                <IonItem>
                <input type="file" id="file-upload" style={{display: "none"}} onChange={photoChange} />
        <IonButton onClick={photoSelect}>Trocar de Foto</IonButton>
        <IonButton onClick={atualiza}>Atualizar</IonButton>
                  </IonItem>
                <IonItem color="light">
                  <IonLabel> <b>Nome do Pet:</b></IonLabel></IonItem>
                <IonItem >
                  <IonInput id="nome" value={nome} placeholder="Ex.: Brad" onIonChange={(e: any) => setNome(e.target.value)} />
                </IonItem>


                <IonItem color="light">
                  <IonLabel><h2><b>Espécie: </b></h2></IonLabel>
                </IonItem>
                <IonList>
                  <IonItem>
                    <IonSelect placeholder="Ex.: Cachorro" onIonChange={(e: any) => setEspecie(e.target.value)}>
                      <IonSelectOption value="Cachorro">Cachorro</IonSelectOption>
                    </IonSelect>

                  </IonItem>
                </IonList>

                <IonItem color="light">
                  <IonLabel><h2><b>Raça: </b></h2></IonLabel>
                </IonItem>
                <IonItem>
                  <IonSelect interface='action-sheet' placeholder="Raca" onIonChange={(e: any) => setRaca(e.target.value)}>
                    {racas.map((raca) => (
                      <IonSelectOption key={raca.raca} value={raca.raca}>
                        {raca.raca}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>

                <IonItem color="light">
                  <IonLabel><h2><b>Sexo: </b></h2></IonLabel>
                </IonItem>
                <IonList>
                  <IonItem>
                    <IonSelect placeholder="Ex.: Macho" onIonChange={(e: any) => setSexo(e.target.value)}>
                    {sexoPet.map((s) => (
                      <IonSelectOption key={s.sexo} value={s.sexo}>
                        {s.sexo}
                      </IonSelectOption>
                    ))}
                    </IonSelect>

                  </IonItem>
                </IonList>

                <IonItem color="light">
                  <IonLabel><h2><b>Peso Atual: </b></h2></IonLabel>
                </IonItem>
                <IonItem>
                  <IonInput id="peso" value={peso_atual} placeholder="Ex.: 15" onIonChange={(e: any) => setPeso(e.target.value)} /> <IonLabel><b>Kg</b></IonLabel>
                </IonItem>

                <IonItem color="light">
                  <IonLabel><h2><b>Data de Nascimento: </b></h2></IonLabel>
                </IonItem>
                <IonItem>
                  <IonInput placeholder="00/00/0000" type="date" name="nascimento" id="nascimento" value={nascimento} onIonChange={(e: any) => setNascimento(e.target.value)}></IonInput>
                </IonItem>

              </IonList>

              <IonButton expand="block" color='warning' onClick={cadastrarPet}><b>Salvar Pet</b></IonButton>


              <IonButton expand="block" color='danger' onClick={dismissDlg}><b>Cancelar</b></IonButton>

            </div>
          </IonContent>
        </IonModal>

      </IonContent>
      <IonFab slot="fixed" vertical="top" horizontal="end">
        <IonFabButton color="dark" id="pet-modal-add" onClick={() => setIsOpenAdd(true)}>
          <IonIcon icon={add}></IonIcon>
        </IonFabButton>

      </IonFab>
     
    </IonPage>


  )
};

export default TabPets;