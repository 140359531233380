import { IonButton, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonList, IonPage, IonSelect, IonSelectOption, IonText, IonTitle, IonToolbar, useIonAlert, useIonLoading } from '@ionic/react';
import { IonCard, IonCardContent, IonCardHeader } from '@ionic/react';

import axios from 'axios';
import { heart} from 'ionicons/icons';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { urlServer } from '../Provedor';
import { estados } from '../Provedor';

import '../Login.css';


const CadDono: React.FC = () => {
  let history = useHistory();

  const [username, setUserName] = useState("");
  const [nomecompleto,setNomeCompleto] = useState("");
  const [dataNascimento,setDataNascimento] = useState("");
  const [genero,setGenero] = useState("");
  const [email,setEmail] = useState("");
  const [senha,setSenha] = useState("");
  const [logradouro,setLogradouro] = useState("");
  const [complemento,setComplemento] = useState("");
  const [numero,setNumero] = useState("");
  const [bairro,setBairro] = useState("");
  const [cidade,setCidade] = useState("");
  const [telefone,setTelefone] = useState("");
  const [cep, setCEP] = useState("");
  const [uf, setUF] = useState("");
  const [presentAlert] = useIonAlert();
  const [present, dismiss] = useIonLoading();

  const jsonCadastro = ({NomeCompleto: nomecompleto, DataNascimento:dataNascimento, Genero:genero, Email:email, Usuario:username, Senha:senha,Logradouro:logradouro,Complemento:complemento,CEP: cep, Numero:numero, Bairro:bairro, Cidade:cidade, UF: uf, Telefone:telefone});

  const httpReq = axios.create({
    baseURL: urlServer
  });

  async function cadastrarDono() {
    
    httpReq.post("/cadastroDono.php", jsonCadastro).then((response) => {
      if(response.data.status == "1") {
        present({
          message: response.data.msg,
          duration: 3000
        });
        history.push('../login');
      } if (response.data.status == "0") {
        present({
          message: response.data.msg,
          duration: 3000
        });
      }
       
    }).catch((error) => {
      console.log(error);
    });
  }

  return (
    
    <IonPage>
      <IonContent fullscreen >
      <div className='spa_cad'>
      <IonCard>
      <IonCardHeader color="dark">
      <IonLabel><h1><IonIcon slot="start"  icon={heart}></IonIcon>  &nbsp;<b>Novo Cadastro</b></h1></IonLabel>
      </IonCardHeader>
      <IonCardContent>
        <br></br>  
       <h1>Bem vinda(o) ao PetsVita!</h1>
       <br></br>
       <p>Aqui você encontra profissionais qualificados para cuidar da saúde do seu pet.</p>

       <IonItem>
       </IonItem>
       <IonLabel><h1>Informe seus dados:</h1></IonLabel>

       <IonItem>
       <IonLabel><b>Nome Completo:</b></IonLabel>
       </IonItem>
       <IonItem>
       <IonInput placeholder="Nome Completo" name="nome" id="nome" value={nomecompleto} onIonChange={(e: any) => setNomeCompleto(e.target.value)}></IonInput>
       </IonItem>

       <IonItem>
       <IonLabel><b>E-mail:</b></IonLabel>
       </IonItem>
       <IonItem>
       <IonInput placeholder="E-mail" type="email" name="email" id="email" value={email} onIonChange={(e: any) => setEmail(e.target.value)}></IonInput>
       </IonItem>
       

       <IonItem>
       <IonLabel><b>Data de Nascimento:</b></IonLabel>
       </IonItem>
       <IonItem>
       <IonInput placeholder="00/00/0000" type="date" name="nascimento" id="nascimento" value={dataNascimento} onIonChange={(e: any) => setDataNascimento(e.target.value)}></IonInput>
       </IonItem>


       <IonItem>
       <IonLabel><b>Telefone:</b></IonLabel>
       </IonItem>
       <IonItem>
       <IonInput placeholder="Telefone" name="telefone" type="tel" id="telefone" value={telefone} onIonChange={(e: any) => setTelefone(e.target.value)}></IonInput>
       </IonItem>

       <IonItem>
       <IonLabel><b>Gênero:</b></IonLabel>
       </IonItem>
       <IonList>
       <IonItem>
       <IonSelect placeholder="Gênero" onIonChange={(e: any) => setGenero(e.target.value)}>
          <IonSelectOption value="M" >Masculino</IonSelectOption>
          <IonSelectOption value="F" >Feminino</IonSelectOption>
          <IonSelectOption value="O" >Outro</IonSelectOption>
        </IonSelect>
      
       </IonItem>
       </IonList>
       <IonItem>
       <IonLabel><b>CEP:</b></IonLabel>
       </IonItem>
       <IonItem>
       <IonInput placeholder="00000-000" name="CEP" id="CEP" value={cep} onIonChange={(e: any) => setCEP(e.target.value)}></IonInput>
       </IonItem>

       <IonItem>
       <IonLabel><b>Logradouro:</b></IonLabel>
       </IonItem>
       <IonItem>
       <IonInput placeholder="Logradouro" name="logradouro" id="logradouro" value={logradouro} onIonChange={(e: any) => setLogradouro(e.target.value)}></IonInput>
       </IonItem>

       <IonItem>
       <IonLabel><b>Número:</b></IonLabel>
       </IonItem>
       <IonItem>
       <IonInput placeholder="1409" name="numero" id="numero" value={numero} onIonChange={(e: any) => setNumero(e.target.value)}></IonInput>
       </IonItem>

       <IonItem>
       <IonLabel><b>Complemento:</b></IonLabel>
       </IonItem>
       <IonItem>
       <IonInput placeholder="" name="complemento" id="complemento" value={complemento} onIonChange={(e: any) => setComplemento(e.target.value)}></IonInput>
       </IonItem>


       <IonItem>
       <IonLabel><b>Bairro:</b></IonLabel>
       </IonItem>
       <IonItem>
       <IonInput placeholder="Bairro" name="bairro" id="bairro" value={bairro} onIonChange={(e: any) => setBairro(e.target.value)}></IonInput>
       </IonItem>

       <IonItem>
       <IonLabel><b>Cidade:</b></IonLabel>
       </IonItem>
       <IonItem>
       <IonInput placeholder="Cidade" name="cidade" id="cidade" value={cidade} onIonChange={(e: any) => setCidade(e.target.value)}></IonInput>
       </IonItem>

       <IonItem>
       <IonLabel><b>Estado:</b></IonLabel>
       </IonItem>
       <IonList>
       <IonItem>
       <IonSelect placeholder="UF"  onIonChange={(e: any) => setUF(e.target.value)}>
       {estados.map((estado) => (
            <IonSelectOption key={estado.uf} value={estado.uf}>
              {estado.nome}
            </IonSelectOption>
          ))}
        </IonSelect>
      
       </IonItem>
       </IonList>

       <IonItem>
       
       </IonItem>
       <IonLabel><b>Dados de Acesso:</b></IonLabel>
       <IonItem>
       <IonLabel><b>Usuário:</b></IonLabel>
       <IonInput placeholder="Usuario" name="usuario" id="usuario" value={username} onIonChange={(e: any) => setUserName(e.target.value)}></IonInput>
       </IonItem>
       <IonItem>
       <IonLabel><b>Senha:</b></IonLabel>
       <IonInput placeholder="Senha" type="password" name="senha" id="senha" value={senha} onIonChange={(e: any) => setSenha(e.target.value)}></IonInput>
       </IonItem>

       <IonItem>
       </IonItem>
       <IonButton expand="block" color="dark" fill="outline" routerLink="./cadTermo">Termo de Responsabilidade</IonButton>
   
       <IonButton expand="block" color="dark" onClick={cadastrarDono}>Cadastrar!</IonButton>
      
      </IonCardContent>
    </IonCard>

    
    </div>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
  
      </IonContent>
    </IonPage>
  );
};

export default CadDono;