import { IonRefresher, IonRefresherContent, RefresherEventDetail ,IonAvatar, IonButton, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonPage, IonText, IonTitle, IonToolbar, useIonAlert, useIonLoading } from '@ionic/react';
import axios from 'axios';
import { eye, trash, add, closeCircle } from 'ionicons/icons';
import { createContext, useEffect, useRef, useState } from 'react';


import { JsonPets, urlServer } from '../pages/Provedor';
import { idDono,getPets } from '../pages/Provedor';
import './ListPets.css';



function ListPets()  {

const [pets, setPets] = useState<any[]>([]);
const modal = useRef<HTMLIonModalElement>(null);
const [isOpen, setIsOpen] = useState(false);
const [idPet, setIdPet] = useState();
const [nome, setNome] = useState();
const [especie, setEspecie] = useState();
const [raca, setRaca] = useState();
const [sexo, setSexo] = useState();
const [peso_atual, setPeso] = useState();
const [url_foto, setUrlFoto] = useState();
const [nascimento, setNascimento] = useState();
const [presentAlert] = useIonAlert();
const [present, dismiss] = useIonLoading();



  const httpReq = axios.create({
    baseURL: urlServer
  });


function dismissDlg() {
  modal.current?.dismiss();
  setIsOpen(false);
}

function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
  getPets(idDono);
  setTimeout(() => {
    event.detail.complete();
    setPets(JsonPets);
  }, 2000);
}

useEffect(() => {
  setPets(JsonPets);
});


////Função para Carregar Informações do Pet
async function loadInfo(indice:any) {
var vNome = pets[indice].nome;
var vIdPet = pets[indice].id_pet;
var vEspecie = pets[indice].especie;
var vRaca = pets[indice].raca;
var vSexo = pets[indice].sexo;
var vPeso = pets[indice].peso_atual;
var vUrlFoto = pets[indice].url_foto;
var vNascimento = pets[indice].nascimento;

if(vNome == pets[indice].nome) {
//Carrega Informaçoes do Pet
setNome(vNome);
setIdPet(vIdPet);
setEspecie(vEspecie);
setRaca(vRaca);
setSexo(vSexo);
setPeso(vPeso);
setUrlFoto(vUrlFoto);
setNascimento(vNascimento);

console.log(vRaca);
//Abre Modal
setIsOpen(true);
}

}

////
async function deletaPet(id : any) {
  const jsonCadastroPet = ({id_pet: id});
  httpReq.post("/deletarPet.php", jsonCadastroPet).then((response) => {
    if (response.data.status == "1") {
      present({
        message: response.data.msg,
        duration: 3000
      });
     
      
    } if (response.data.status == "0") {
      present({
        message: response.data.msg,
        duration: 3000
      });
    }

  }).catch((error) => {
    console.log(error);
  });
}



//////
if(!pets[0]) {

return ( 
  <>
<IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher> 

  <IonList>
   
          <IonItem>
            <IonLabel>Não há Pets cadastrados!</IonLabel>
          </IonItem>
   
    </IonList></>
);
  
  } else { 

    return (
      
    <>
    
    <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher> 
    <IonList>
   
      {
      //Mapeamento dos Itens (Pets)
      pets.map((pet, index) => {
        return (
          <IonItem key={index}>
             
            <IonAvatar>
              <img src={pet.url_foto} />
            </IonAvatar>
            <IonLabel>{pet.nome}</IonLabel>

            <IonButton  color="dark" fill="outline" onClick={() => loadInfo(index)}>
            <IonIcon icon={eye} />
            </IonButton>
            <IonButton  color="dark" fill="outline" onClick={() =>
              presentAlert({
                header: 'Confirma a remoção desse Pet ?',
                buttons: [
                  {
                    text: 'Não',
                    role: 'cancel',
                    handler: () => {
                      //faça nada
                    },
                  },
                  {
                    text: 'Sim',
                    role: 'confirm',
                    handler: () => {
                      deletaPet(pet.id_pet);
                    },
                  },
                ],
                
              
      })}>
            <IonIcon icon={trash} />
            </IonButton>
          </IonItem>
          
        );
      })
      }
   
    </IonList>
    
    <IonModal animated={true} keepContentsMounted={true} id="pet-modal" isOpen={isOpen}>
    
    <IonContent className="ion-padding">
        <div className="wrapper">
          
          <IonList lines="none">
          <br></br>
          <IonItem>
          <IonButton color="danger" slot="end" onClick={() => dismissDlg()} className='bt_fechar'>
            <b>Fechar</b>
              <IonIcon icon = {closeCircle} />
            </IonButton>
            </IonItem>
          <IonItem>
          <IonAvatar className='profile_pet'>
            <img id="photoAvatar" src={url_foto} />
            </IonAvatar>
      
            <IonLabel> <h1><b>{nome}</b></h1> </IonLabel>
          </IonItem>

          <IonItem color="light">
          <IonLabel><h2><b>Espécie: </b></h2></IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel><h2>{especie}</h2></IonLabel>
          </IonItem>

          <IonItem color="light">
          <IonLabel><h2><b>Raça: </b></h2></IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel><h2>{raca}</h2></IonLabel>
          </IonItem>

          <IonItem color="light">
          <IonLabel><h2><b>Sexo: </b></h2></IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel><h2>{sexo}</h2></IonLabel>
          </IonItem>

          <IonItem color="light">
          <IonLabel><h2><b>Peso Atual: </b></h2></IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel><h2>{peso_atual} <b>Kg</b></h2></IonLabel>
          </IonItem>

          <IonItem color="light">
          <IonLabel><h2><b>Data de Nascimento: </b></h2></IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel><h2>{nascimento}</h2></IonLabel>
          </IonItem>
      
          </IonList>
          
        <IonButton expand="block" color='warning'><b>Salvar Informações</b></IonButton>
       
        
        <IonButton expand="block" color='danger' onClick={() => dismissDlg()}><b>Cancelar</b></IonButton>
        
        </div>
        </IonContent>
      </IonModal>


    
 </>
      );
}};

export default ListPets;
