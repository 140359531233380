import axios from "axios";
import { useState } from "react";

//Url do Servidor Back-END
export const urlServer : string = "https://rlamateriais.com.br/petsvita";


//Sexo dos Pets

export const sexoPet = [{"sexo":"Macho"},{"sexo":"Femea"}];

//Raças dos Dogs
export const racas = [
  {
    "raca": "Akita"
  },
  {
    "raca": "Basset hound"
  },
  {
    "raca": "Beagle"
  },
  {
    "raca": "Bichon frisé"
  },
  {
    "raca": "Boiadeiro australiano"
  },
  {
    "raca": "Border collie"
  },
  {
    "raca": "Boston terrier"
  },
  {
    "raca": "Boxer"
  },
  {
    "raca": "Buldogue francês"
  },
  {
    "raca": "Buldogue inglês"
  },
  {
    "raca": "Bull terrier"
  },
  {
    "raca": "Cane corso"
  },
  {
    "raca": "Cavalier king charles spaniel"
  },
  {
    "raca": "Chihuahua"
  },
  {
    "raca": "Chow chow"
  },
  {
    "raca": "Cocker spaniel inglês"
  },
  {
    "raca": "Dachshund"
  },
  {
    "raca": "Dálmata"
  },
  {
    "raca": "Doberman"
  },
  {
    "raca": "Dogo argentino"
  },
  {
    "raca": "Dogue alemão"
  },
  {
    "raca": "Fila brasileiro"
  },
  {
    "raca": "Golden retriever"
  },
  {
    "raca": "Husky siberiano"
  },
  {
    "raca": "Jack russell terrier"
  },
  {
    "raca": "Labrador retriever"
  },
  {
    "raca": "Lhasa apso"
  },
  {
    "raca": "Lulu da pomerânia"
  },
  {
    "raca": "Maltês"
  },
  {
    "raca": "Mastiff inglês"
  },
  {
    "raca": "Mastim tibetano"
  },
  {
    "raca": "Pastor alemão"
  },
  {
    "raca": "Pastor australiano"
  },
  {
    "raca": "Pastor de Shetland"
  },
  {
    "raca": "Pequinês"
  },
  {
    "raca": "Pinscher"
  },
  {
    "raca": "Pit bull"
  },
  {
    "raca": "Poodle"
  },
  {
    "raca": "Pug"
  },
  {
    "raca": "Rottweiler"
  },
  {
    "raca": "Schnauzer"
  },
  {
    "raca": "Shar-pei"
  },
  {
    "raca": "Shiba"
  },
  {
    "raca": "Shih tzu"
  },
  {
    "raca": "Staffordshire bull terrier"
  },
  {
    "raca": "Weimaraner"
  },
  {
    "raca": "Yorkshire"
  }
];

export const estados = [{"nome": "Acre", "uf": "AC"},
{"nome": "Alagoas", "uf": "AL"},
{"nome": "Amapá", "uf": "AP"},
{"nome": "Amazonas", "uf": "AM"},
{"nome": "Bahia", "uf": "BA"},
{"nome": "Ceará", "uf": "CE"},
{"nome": "Distrito Federal", "uf": "DF"},
{"nome": "Espírito Santo", "uf": "ES"},
{"nome": "Goiás", "uf": "GO"},
{"nome": "Maranhão", "uf": "MA"},
{"nome": "Mato Grosso", "uf": "MT"},
{"nome": "Mato Grosso do Sul", "uf": "MS"},
{"nome": "Minas Gerais", "uf": "MG"},
{"nome": "Pará", "uf": "PA"},
{"nome": "Paraíba", "uf": "PB"},
{"nome": "Paraná", "uf": "PR"},
{"nome": "Pernambuco", "uf": "PE"},
{"nome": "Piauí", "uf": "PI"},
{"nome": "Rio de Janeiro", "uf": "RJ"},
{"nome": "Rio Grande do Norte", "uf": "RN"},
{"nome": "Rio Grande do Sul", "uf": "RS"},
{"nome": "Rondônia", "uf": "RO"},
{"nome": "Roraima", "uf": "RR"},
{"nome": "Santa Catarina", "uf": "SC"},
{"nome": "São Paulo", "uf": "SP"},
{"nome": "Sergipe", "uf": "SE"},
{"nome": "Tocantins", "uf": "TO"}
];

const httpReq = axios.create({
    baseURL: urlServer
  });


export var JsonDados : any = [];
export var JsonPets : [];
export var idDono : any;

//Obter Perfil do Dono
function getDados(id : any) {
const jsond = ({idUser: id});
httpReq.post("PerfilJSON.php", jsond).then((response) => {
  JsonDados = response.data;
}
).catch((error) =>
{
  console.log("Ocorreu um erro no Sistema."); 
}
)}
//////////////////////

//Função para Passar ID para Variavel
export function setId(id : any) {
  return idDono = id;
}

//Obter Pets referente ao Dono
export function getPets(idDono : any) {
  const jsond = ({idUser : idDono});
  httpReq.post("/obterPets.php", jsond).then((response) => {
    JsonPets = response.data;  
  }).catch((error) =>
  {
   console.log("Ocorreu um erro no Sistema."); 
});

}


export default getDados;