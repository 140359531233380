import { IonButton, IonCard, IonCardContent, IonCardHeader, IonContent, IonHeader, IonItem, IonPage, IonTitle, IonToolbar } from '@ionic/react';

import '../Login.css';

const CadTermo: React.FC = () => {
  return (
      <IonPage>
      <IonContent>
   
        <IonCard>
      <IonCardHeader color="dark">
      Termo de Responsabilidade
      </IonCardHeader>
      <IonCardContent>
<br></br>

<br></br>
<br></br>
<b>I – PREÂMBULO</b>
<br></br>
<br></br>
Ao utilizar o aplicativo reconheço que estes termos de uso e o meu cadastro junto ao Pets Vita não estabelece qualquer tipo de vínculo societário, associativo ou cooperativo, empregatício direto ou indiretamente.  Sendo o aplicativo Pets Vita uma ferramenta auxiliar à prestação dos serviços regularmente executado conforme descrito, ficando de livre escolha do usuário a adesão, entendimento e concordância com as descrições nesse termo de utilização.
<br></br>
<br></br>
<b>II - UTILIZAÇÃO DO APLICATIVO</b>
<br></br>
<br></br>
Para à utilização, basta o usuário baixar e cadastrar-se no aplicativo, não havendo qualquer custo para baixar e utilizar o aplicativo.
A utilização do aplicativo Pets Vita implica na concordância expressa do direito de acesso e armazenamento da localização geográfica fornecida pelo serviço GPS do aparelho celular do usuário para que possa ter acesso aos serviços disponíveis mais próximos de sua localização. Uma vez instalado o aplicativo, o usuário irá visualizar os profissionais disponíveis para atendimento em sua região de atuação.
O pagamento dos serviços oferecidos pelos médicos veterinários serão pagos diretamente ao profissional, sem intervenção da Pets Vita, sendo de livre escolha a solicitação de atendimento e valor estabelecido pelo prestador de serviço. Ficando a critério do prestador de serviço e usuário as formas de pagamento conforme definido previamente pelo prestador de serviço.

Pets Vita: aplicativo de serviços Veterinário que fará a aproximação entre Usuário e prestador de serviço Médico Veterinário por livre escolha do usuário. 
Médico Veterinário: profissional com inscrição ativa no CRMV, eleito livremente pelo usuário e responsável pelo atendimento, de acordo com suas convicções  sem intervenção da Pets Vita.
Usuário: pessoa física ou jurídica que acessa o aplicativo em busca de serviços veterinário de sua livre escolha, para atendimento a si ou à terceiros, de acordo com os critérios de sua maior conveniência de acesso/localização, especialidade e serviços, sem intervenção do aplicativo.
<br></br>
<br></br>
<b>III – CUSTO</b>
<br></br>
<br></br>
Ao cadastrar-se no aplicativo Pets Vita, o prestador de serviço Veterinário e usuário não terá nenhum custo, nem tampouco para manter-se ativo. A relação de custo existente ficará a critério do usuário em função da relação existente entre o prestador de serviço médico veterinário e usuários, tendo o usuário o custo da consulta/atendimento definido previamente  pelo prestador de serviço (médico veterinário) e aceito pelo usuário, no momento do atendimento agendado.
O prestador de serviço médico veterinário, clínicas e hospitais veterinário tem total liberdade acerca do valor correspondente aos seus honorários ou produtos, podendo alterar, para mais ou para menos, a qualquer tempo, para novos chamados. Depois de aceito um determinado atendimento, por óbvio, não haverá modificação para aquele atendimento aceito.
<br></br><br></br>
<b>IV - RESPONSABILIDADES E POLITICA DE PRIVACIDADE </b>
<br></br>
<br></br>
Pets Vita implementará medidas que considera apropriadas para salvaguardar e ajudar a evitar o acesso, alteração, divulgação ou destruição sem autorização das informações do usuário coletadas por meio do aplicativo. No entanto, não se responsabiliza pelo uso indevido, por terceiros, das informações fornecidas pelo usuário. Caso o usuário considere ter suas informações utilizadas indevidamente por terceiro. Os e em desacordo com o presente termo, este deverá entrar em contato por meio do canal de comunicação petsvita@gmail.com que analisará a reclamação e tornará as medidas que considerar cabíveis.
Pets Vita coletará as informações pertinentes às transações de solicitação de serviços realizados por meio do aplicativo e poderá divulga-las para fins específicos da utilização do aplicativo. Caso tenha razões para crer, a seu exclusivo critério, que o usuário esteja envolvido em qualquer atividade considerada ilegal, que viole quaisquer das cláusulas do presente termo ou que leve à suspensão ou encerramento de acesso ao aplicativo.
Pets Vita não será responsável por quaisquer perdas de dados do usuário, inclusive decorrentes de caso fortuito, força maior, ocorridas em virtude de invasões ao aplicativo e quebra de segurança por parte de terceiros não autorizados. Todas as informações coletadas por meio do aplicativo são armazenadas em ambientes seguros e de acesso restrito ao público.
Pets Vita não se responsabiliza por danos sofridos pelo usuário em razão de cópia, transferência, distribuição ou qualquer outra forma de utilização de conteúdo protegido disponibilizado no aplicativo.
Pets Vita coletará, armazenará, tratará e poderá, a seu critério, transmitir ou disponibilizar a terceiros os dados e informações fornecidos pelo usuário ao efetuar o cadastro no aplicativo, não se limitando à sua localização, nome completo, apelido, foto de perfil, telefone, dentre outros.
Ao aceitar este termo o usuário concorda em permitir que a Pets Vita realize o tratamento dos dados disponibilizados por meio do aplicativo, podendo utilizar os dados pessoais agregados e /ou anônimos de seus usuários para elaboração de relatórios, análises e transferência a terceiros, conforme for necessário para o bom funcionamento do aplicativo.
<br></br><br></br>
<b>V - DISPOSIÇÕES GERAIS</b>
<br></br>
<br></br>
Para acompanhar as constantes evoluções dos softwares em geral, a qualquer momento o termo de utilização poderá ser alterado e de maneira unilateral, desde que imediatamente atualizada as modificações no próprio termo disponível para baixa pelos usuários. Nestes casos, o usuário receberá um e-mail de alerta, informando as novidades, sendo certo que a continuidade da parceria representará aceitação tácita acerca das modificações.
O usuário permite o envio de e-mail inserido no cadastro. Ainda com foco na boa fé coletiva que deve nortear toda e qualquer relação, poderá excluir os usuários quando detectado o mau uso.
Por mau uso, entende-se, dentre vários aspectos, comportamento que atende contra a politica de privacidade da Pets Vita, respectiva licença, registro da marca, propriedade intelectual e/ou concorrência.
<br></br>
<br></br>
<br></br>
      <IonButton expand="block" color="dark" routerLink="./cadDono">Voltar</IonButton>
      </IonCardContent>
      </IonCard>

      </IonContent>
      </IonPage>
  );
};

export default CadTermo;
