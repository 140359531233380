import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonButton, IonButtons, IonContent, IonHeader, IonRouterOutlet, IonTab, IonTitle, IonToolbar, useIonViewDidEnter, useIonViewWillEnter, IonList, IonItem, IonThumbnail, IonFab, IonFabButton, IonModal, IonAvatar, IonInput, IonSelect, IonSelectOption, useIonAlert, useIonLoading, IonText } from '@ionic/react';

import { IonPage, IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel, IonBadge } from '@ionic/react';
import { IonReactMemoryRouter, IonReactRouter } from '@ionic/react-router';

import { personCircle } from 'ionicons/icons';
import { eye, trash, add, refresh, camera, closeCircle } from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import './PainelDono.css';
import { useHistory } from 'react-router';
import ListPets from '../components/ListPets';
import { urlServer } from './Provedor';
import { racas } from './Provedor';
import axios from 'axios';
import { idDono, getPets } from './Provedor';
import { render } from 'react-dom';

const TabAgendamentos: React.FC = () => {

  return (
    <IonPage>
      <IonContent fullscreen>
        <IonCard>
          <IonCardHeader>
            <IonCardTitle><b>Meus Agendamentos</b></IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
           <IonText>Estamos quase terminando de implementar tudinho!</IonText>
          </IonCardContent>
        </IonCard>


      </IonContent>

    </IonPage>


  )
};

export default TabAgendamentos;