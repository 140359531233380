import {  IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,IonButton, IonButtons, IonContent, IonHeader, IonRouterOutlet, IonTab, IonTitle, IonToolbar, useIonViewDidEnter, useIonViewWillEnter, IonList, IonItem, IonThumbnail } from '@ionic/react';

import { IonPage,IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel, IonBadge } from '@ionic/react';
import { IonReactMemoryRouter, IonReactRouter } from '@ionic/react-router';
import axios from 'axios';

import { calendar, searchSharp, eyedropSharp, pawSharp,person,call, settings, personCircle, map, informationCircle, calendarSharp } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';
import './PainelDono.css';

import  getDados, { JsonDados, JsonPets, getPets }  from './Provedor';
import { idDono } from './Provedor';
import ListPets from '../components/ListPets';


  const TabPerfil: React.FC = () => {

    useEffect(() => {
    getDados(idDono);
    getPets(idDono);
  });
    return(
      <IonPage>
      <IonContent fullscreen>
      <IonCard>
      <IonCardHeader>
        <IonCardTitle>Olá, {JsonDados['nome']}</IonCardTitle>
      </IonCardHeader>

      <IonCardContent>
      <IonList>
          <IonItem>
            <IonThumbnail slot="start">
              <img alt="Foto do Perfil" src="https://ionicframework.com/docs/img/demos/thumbnail.svg" />
            </IonThumbnail>
            <IonLabel>{JsonDados['nome']}</IonLabel>
           
          </IonItem>
          <IonItem>
            <IonLabel>E-Mail: {JsonDados['email']}</IonLabel>
            </IonItem>
      </IonList>
      </IonCardContent>
    </IonCard>

  
    <IonCard>
      <IonCardHeader>
        <IonCardTitle><b>Meus Pets</b></IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
     <ListPets />
      </IonCardContent>
    </IonCard>


    <IonCard>
      <IonCardHeader>
        <IonCardTitle><b>Meus Agendamentos</b></IonCardTitle>
      </IonCardHeader>

      <IonCardContent>
    
      </IonCardContent>
    </IonCard>
      </IonContent>
    </IonPage>
     
  )};

  export default TabPerfil;