
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { IonPage,IonTabs, IonTabBar, IonTabButton, IonIcon, IonLabel, IonBadge } from '@ionic/react';
import { calendar,person,call, settings, personCircle, map, informationCircle } from 'ionicons/icons';
import Home from './pages/Home';
import Login from './pages/Login';
import Menu from './pages/Menu';
import LoginVet from './pages/LoginVet';
import PainelDono from './pages/PainelDono';
import cadDono from './pages/cadDono/cadDono';
import CadTermo from './pages/cadDono/cadTermo'


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';


setupIonicReact();

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/" > <Redirect to="/Menu" /> <Menu /> </Route>
        <Route exact path="/login" component={Login}  />
        <Route exact path="/loginVet" component={LoginVet}  />
        <Route exact path="/PainelDono" component={PainelDono} />
        <Route exact path="/cadDono/cadDono" component={cadDono} />
        <Route exact path="/cadDono/cadTermo" component={CadTermo} />
        <Route exact path="/Menu" component={Menu}  />
       
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;
