import { IonButton, IonIcon, IonCard, IonCardContent, IonCardHeader, IonContent, IonHeader, IonLabel, IonPage, IonTitle, IonToolbar, IonItem, IonInput } from '@ionic/react';
import { heart, medkit, keyOutline} from 'ionicons/icons';
import './LoginVet.css';

const Login: React.FC = () => {

  function quasela() {
    alert("Estamos quase terminando de implementar tudinho !!!");
  }
    return (
      <IonPage>
  
        <IonContent fullscreen className='ion-padding'>
        <div className='espaco_login'>
      <IonCard>
      <IonCardHeader color="dark">
      <IonLabel><h1> <IonIcon slot="start"  icon={medkit}></IonIcon><b>  &nbsp;Sou Veterinário</b></h1>
     </IonLabel>
      </IonCardHeader>
      <IonCardContent className='card_login'> 
       <br></br>
      <IonLabel><b>Usuário:</b></IonLabel>
      <IonItem>
        <IonInput placeholder="Usuário, Email, Telefone...."></IonInput>
      </IonItem>
      <br></br>
      <IonLabel><b>Senha:</b></IonLabel>
      <IonItem>
        <IonInput type='password'></IonInput>
      </IonItem>
      <IonItem>
      <IonLabel color="danger">Esqueci minha senha!</IonLabel> 
      </IonItem>
      <IonItem>
      <IonLabel color="primary"  onClick={() => quasela()}>Não tem cadastro? Cadastre-se!</IonLabel> 
      </IonItem>
      </IonCardContent>
      <br></br>
      
      <IonButton color="dark" expand="block" onClick={() => quasela()}>
      <IonIcon slot="start"  icon={keyOutline}></IonIcon>
        Entrar
      </IonButton>
    </IonCard>
    </div>
        </IonContent>
      </IonPage>
    );
  };
  
  export default Login;
  